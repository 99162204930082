import React from "react"
import { SEO } from '../components/common';
import { Base, Header } from "../components/Layout"
import { Career } from "../components/join_us/career"


const MenuPage = () => (
  <Base overlay={true}>
    <SEO title="Join us"
         location="/join_us"
         description="Want to join the Kakilang team, and be part of the family? We offer positions ranging from bubble tea baristas, front of house, chef assistants & pastry chefs."/>
    <Header />
    <Career />
  </Base>
)

export default MenuPage
