import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Banner, Container, Wrapper } from "./style"
import { Paragraph, Title } from "../common"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"


export const Career = () => {
  const data = useStaticQuery(
    graphql`
        query {
            job: contentfulJobs {
              title
              text {
                raw
              }
            }
            banner: file(relativePath: {eq: "banner_1.jpg"}) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          }
    `
  )
  const { job } = data
  return (
    <Wrapper>
      <Banner>
        <Img fluid={data.banner.childImageSharp.fluid} alt="Join Kakiang" />
      </Banner>
      <Container>
        <Title style={{ textTransform: "inherit" }}>Join Us</Title>
        <Paragraph>{renderRichText(job.text)}</Paragraph>
      </Container>
    </Wrapper>
  )
}
