import styled from "styled-components"


export const Wrapper = styled.div`
  h1 {
    margin: 2rem 0;
  }
  >p {
    margin: 2rem 0;
  }
`
export const Container = styled.div`
  width: 70%;
  margin: 3rem auto 5rem;
  @media (max-width: 680px) {
    width: 80%;
    margin: 1.5rem auto 1rem;
  }
`



export const Banner = styled.div`
  text-align: center;
`

export const MenuImage = styled.div`
  padding: 0 8rem;

  @media (max-width: 960px) {
    padding: 0 2rem;
  }
`
